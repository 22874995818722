import React, { useState } from "react";
import PropTypes from "prop-types";
import logo from "../../assets/images/logo.svg";
import {
  Navbar,
  TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col
} from "reactstrap";

import { closeSidebar, openSidebar } from "../../actions/navigation";
import MenuIcon from "../Icons/HeaderIcons/MenuIcon";
import SearchIcon from "../Icons/HeaderIcons/SearchIcon";

import userImg from "../../assets/james.jpg";

import s from "./Header.module.scss";
import "animate.css";

const LabHeader = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  
  const toggleNotifications = () => {
    setNotificationsOpen(!notificationsOpen);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleSidebar = () => {
    if (props.sidebarOpened) {
      props.dispatch(closeSidebar());
    } else {
      const paths = props.location.pathname.split("/");
      paths.pop();
      props.dispatch(openSidebar());
    }
  };

  return (
    <div>
    <Navbar className={`${s.root} d-print-none lab-nav`}>
       <img className="lab-logo" src={logo} />
      
    
    </Navbar>
    {
        (props.role === "STUDENT" &&
        props.count !== undefined && 
        props.count !== 0 && props.count)?<div className="notification-bubble"><span class="notranslate">{props.count}</span></div>:("")
        // <div className="notification-bubble"> {props.count !== undefined && props.count !== 0 && props.count} </div> 
       }
<div>
  
</div>
</div>
  );
};

export default LabHeader;
